/* eslint-disable @next/next/no-img-element */
import { useGetBadges } from 'api-hooks/notification/query';
import classNames from 'classnames';
import { SessionToken, useAuth } from 'common/auth';
import { AssetPaths } from 'common/constants/assets';
import { UserLevelEnum } from 'common/constants/enum';
import { NavigationRoutes } from 'common/routes';
import Text from 'components/elements/text/base';
import { AppHeader } from 'components/widgets/header';
import { DefaultAppLayoutActionKey } from 'components/widgets/layout/actions/context';
import { useIsSmallScreen } from 'hooks/use-is-small-screen';
import React from 'react';
import { useTranslation } from 'react-i18next';
import structuralStyles from 'styles/layout.css';

import { ShoppingCartActionIcon } from './components/icons';

interface Props {
  tab: NavigationRoutes;
  title?: React.ReactNode;
}

export default function KurosimHeader(props: Props) {
  const { tab, title: customTitle } = props;
  const { t } = useTranslation();
  const isMobile = useIsSmallScreen();

  const query = useAuth();
  const me = query.data?.data;

  const title =
    (customTitle ?? me)
      ? t('home:hello_extra', { extra: me?.firstName })
      : t('home:hello_friend');

  const isLoggedIn = query.isAuthenticated;

  const { data } = useGetBadges({
    staleTime: 15000, //15 seconds
    enabled: !!SessionToken.get()?.accessToken,
  });

  const shoppingComponent = isLoggedIn && (
    <ShoppingCartActionIcon indicator={data?.data?.cart?.count} />
  );

  if (tab === NavigationRoutes.Store || tab === NavigationRoutes.MySim) {
    return (
      <>
        <AppHeader
          title={
            isMobile ? (
              <img
                width={128}
                alt="kurosim"
                src={AssetPaths.KurosimFeatTopremit}
              />
            ) : (
              title
            )
          }
          allowedDefaultActions={[
            DefaultAppLayoutActionKey.Notifications,
            DefaultAppLayoutActionKey.ContactUs,
          ]}
          actions={<>{shoppingComponent}</>}
        />
      </>
    );
  }

  if (tab === NavigationRoutes.Profile) {
    return (
      <AppHeader
        title={title}
        allowedDefaultActions={[
          DefaultAppLayoutActionKey.Notifications,
          DefaultAppLayoutActionKey.ContactUs,
        ]}
      />
    );
  }

  if (tab === NavigationRoutes.Referral) {
    return (
      <AppHeader
        title={
          me?.level === UserLevelEnum.Affiliate ? (
            <div className={structuralStyles.flexbox({ gap: 4 })}>
              <Text
                className={classNames(
                  structuralStyles.fill({ width: true }),
                  structuralStyles.text({
                    overflow: 'ellipsis',
                    wrap: false,
                  }),
                )}
                textVariant="h2"
              >
                {t('referral:affiliate_referral')}
              </Text>
              <img
                src={AssetPaths.AffiliateStatus}
                alt={t('referral:affiliate_account')}
                width={24}
                height={24}
              />
            </div>
          ) : (
            t('common:referral')
          )
        }
        allowedDefaultActions={[
          DefaultAppLayoutActionKey.Notifications,
          DefaultAppLayoutActionKey.ContactUs,
        ]}
      />
    );
  }

  return (
    <AppHeader
      allowedDefaultActions={[
        DefaultAppLayoutActionKey.Notifications,
        DefaultAppLayoutActionKey.ContactUs,
      ]}
    />
  );
}

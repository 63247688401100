import { useAuth } from 'common/auth';
import { Referral } from 'common/repositories/referral';
import { NavigationRoutes } from 'common/routes';
import useKurosimNavigation, {
  StackNavigation,
} from 'hooks/use-kurosim-navigation';
import React from 'react';

interface KurosimMobileReferralRedirectionProps {
  navigate: NavigationRoutes;
}

/** Note that the logic of this component is incomplete as it doesn't handle cases when user logs in via provider and doesn't access Kurosim through a referral code link. That edge case is handled by HandleOauthRefreshComponent. Very dirty code, but it's the simplest way to access oauth information without doing some mutation cache or context magic which is prone to issues like: how do we clear the data after logout? etc */
export default function KurosimMobileReferralRedirection(
  props: KurosimMobileReferralRedirectionProps,
) {
  const [hasReferral, setHasReferral] = React.useState(false);
  const { push } = useKurosimNavigation();
  const { isAuthenticated, isLoading } = useAuth();

  React.useEffect(() => {
    if (typeof window === 'undefined') return;
    //reset
    const hasReferral = StackNavigation.get().find(
      (route) => route.pathname === NavigationRoutes.ReferralBind,
    );

    setHasReferral(!!hasReferral);
    StackNavigation.resetTo(props.navigate);
  }, [props.navigate]);

  React.useEffect(() => {
    if (typeof window === 'undefined') return;
    const referralStored = Referral.get();
    if (isLoading || !isAuthenticated || !referralStored) return;
    if (hasReferral) {
      push(NavigationRoutes.ReferralBind, {
        query: {
          code: referralStored,
        },
      });
    }
  }, [hasReferral, isAuthenticated, isLoading, push]);

  return null;
}

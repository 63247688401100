import { CustomValue } from 'common/repositories/customization';
import { NavigationRoutes } from 'common/routes';
import { ColorfulBackgroundElement } from 'components/common/colorful-background';
import { UseQueryWrapperComponent } from 'components/common/fetch-wrapper-component';
import { PullRefresh } from 'components/common/pull-refresh';
import KurosimMobileReferralRedirection from 'components/kurosim-mobile-referral-redirection';
import { AppLayout } from 'components/widgets/layout';
import { ResizeWhenScreen } from 'hooks/use-is-small-screen';
import { useGetEsimsCached } from 'modules/esim/hooks/use-get-esim-cached';
import KurosimHeader from 'modules/main/header';
import MySimScreen from 'modules/main/my-sim';
import SimEmptyView from 'modules/main/my-sim/empty-view';
import KurosimBottomNavigationBar from 'modules/main/navigation';
import { useOnboardingOnFirstVisit } from 'modules/onboarding';
import React from 'react';
import structuralStyles from 'styles/layout.css';

export default function MySimLayout() {
  const query = useGetEsimsCached({});
  const data = query.data?.pages
    .flatMap((page) => page.data)
    .filter((data) => data.archivedAt == null);

  useOnboardingOnFirstVisit();

  return (
    <>
      <AppLayout
        Header={<KurosimHeader tab={NavigationRoutes.MySim} />}
        Footer={<KurosimBottomNavigationBar tab={NavigationRoutes.MySim} />}
        customization={{
          body: {
            className: CustomValue.overwrite(
              structuralStyles.padding({ top: 16 }),
            ),
          },
        }}
      >
        <ColorfulBackgroundElement top={128} right={-160} />
        <ResizeWhenScreen
          mobile={<ColorfulBackgroundElement top={-160} left={-240} />}
          desktop={
            <ColorfulBackgroundElement top={-224} left={-144} degrees={120} />
          }
        />
        <UseQueryWrapperComponent query={query}>
          <PullRefresh onRefresh={query.refetch} isLoading={query.isRefetching}>
            {data ? <MySimScreen data={data} /> : <SimEmptyView />}
          </PullRefresh>
        </UseQueryWrapperComponent>
      </AppLayout>
      <KurosimMobileReferralRedirection navigate={NavigationRoutes.MySim} />
    </>
  );
}

import { Carousel, Embla } from '@mantine/carousel';
import { useMediaQuery } from '@mantine/hooks';
import classNames from 'classnames';
import { AssetPaths } from 'common/constants/assets';
import { LocalStorageKeys } from 'common/constants/browser-storage-keys';
import { NavigationRoutes } from 'common/routes';
import breakpoints from 'common/styles/breakpoint';
import Button from 'components/elements/button';
import Text from 'components/elements/text';
import { AppFooter } from 'components/widgets/footer';
import { AppLayout } from 'components/widgets/layout';
import useDetectDevice from 'hooks/use-detect-device';
import useKurosimNavigation, {
  StackNavigation,
} from 'hooks/use-kurosim-navigation';
import { createHistoryEntry } from 'hooks/use-kurosim-navigation/utils';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import structuralStyles from 'styles/layout.css';

import { OnboardingCarouselStyle } from './styles.css';

export function useOnboardingOnFirstVisit() {
  const { isKurosimApp } = useDetectDevice();
  const { replace } = useKurosimNavigation();
  React.useEffect(() => {
    if (isKurosimApp) return; // ignore when app
    if (!localStorage.getItem(LocalStorageKeys.HasShownOnboarding)) {
      replace(NavigationRoutes.Guides, { query: undefined });
    }
  }, [isKurosimApp, replace]);
}

export default function Onboarding() {
  const { t } = useTranslation();
  const { replace, refresh } = useKurosimNavigation();
  const [embla, setEmbla] = React.useState<Embla | null>(null);

  const query = useRouter()?.query?.q?.toString().toLowerCase();
  const isGuide = query === 'guide';

  const slides = [
    {
      background: AssetPaths.OnboardingBackgroundFirst,
      image: AssetPaths.OnboardingFirst,
      i18nKey: 'profile:onboarding_01',
    },
    {
      background: AssetPaths.OnboardingBackgroundSecond,
      image: AssetPaths.OnboardingSecond,
      i18nKey: 'profile:onboarding_02',
    },
    {
      background: AssetPaths.OnboardingBackgroundFirst,
      image: AssetPaths.OnboardingThird,
      i18nKey: 'profile:onboarding_03',
    },
    {
      background: AssetPaths.OnboardingBackgroundSecond,
      image: AssetPaths.OnboardingFourth,
      i18nKey: 'profile:onboarding_04',
    },
  ];

  React.useEffect(() => {
    if (!embla) return;
    if (typeof window !== 'undefined') {
      localStorage.setItem(LocalStorageKeys.HasShownOnboarding, 'true');
    }
    const intervalId = setInterval(() => {
      embla.scrollNext();
    }, 4000);
    return () => clearInterval(intervalId as any);
  }, [embla]);

  const onboardingId = `onboarding--${React.useId()}--slide`;

  const isSmall = useMediaQuery(breakpoints.screenMaxSm);

  return (
    <AppLayout
      noSideNavigation
      Footer={
        <AppFooter
          customization={{ root: { border: !!isGuide } }}
          mainButtonProps={{
            type: 'main',
            fullWidth: true,
            onClick: () => {
              StackNavigation.popWhile(
                (entry) => entry.pathname === NavigationRoutes.Guides,
                createHistoryEntry(NavigationRoutes.MySim),
              );
              refresh();
            },
            variant: { variant: isGuide ? 'primary' : 'secondary' },
            children: isGuide ? t('common:done') : t('common:explore'),
          }}
          Above={
            isGuide ? undefined : (
              <Button fullWidth onClick={() => replace(NavigationRoutes.Login)}>
                {`${t('common:login')} / ${t('common:sign_up')}`}
              </Button>
            )
          }
        />
      }
    >
      <div
        className={classNames(
          structuralStyles.fill({ height: true }),
          structuralStyles.flexbox({ direction: 'column', fill: true }),
        )}
      >
        <Carousel
          height="100%"
          withIndicators
          withControls={false}
          getEmblaApi={setEmbla}
          classNames={OnboardingCarouselStyle}
          style={{ flex: 1 }}
        >
          {slides.map((slide, idx) => (
            <Carousel.Slide
              key={idx}
              style={{
                backgroundImage: `url(${slide.background})`,
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <div
                className={classNames(
                  structuralStyles.flexbox({
                    direction: 'column',
                    justify: 'center',
                  }),
                  structuralStyles.fill({
                    height: true,
                  }),
                )}
              >
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  src={slide.image}
                  style={{
                    height: 'max(300px, 50dvh)',
                    objectFit: 'contain',
                  }}
                  aria-labelledby={`${onboardingId}--${idx}--title`}
                />
                <Text
                  ta="center"
                  mt={24}
                  mx={24}
                  maw={isSmall ? 320 : 500}
                  id={`${onboardingId}--${idx}--title`}
                >
                  <Trans
                    i18nKey={slide.i18nKey}
                    components={[
                      <Text
                        key="component-1"
                        textVariant="body1Semibold"
                        span
                      />,
                    ]}
                  />
                </Text>
              </div>
            </Carousel.Slide>
          ))}
        </Carousel>
      </div>
    </AppLayout>
  );
}

import { AssetPaths } from 'common/constants/assets';
import { NavigationRoutes } from 'common/routes';
import Button from 'components/elements/button';
import useKurosimNavigation from 'hooks/use-kurosim-navigation';
import { EmptyView } from 'modules/status';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  archive?: boolean;
}

export default function SimEmptyView(props: Props) {
  const { archive } = props;
  const { t } = useTranslation();
  const { replace } = useKurosimNavigation();

  return (
    <EmptyView
      imageSource={AssetPaths.NoEsim}
      title={
        archive ? t('error:archive_empty_title') : t('error:sim_empty_title')
      }
      description={
        archive ? t('error:archive_empty_desc') : t('error:sim_empty_desc')
      }
      below={
        archive && (
          <Button fullWidth onClick={() => replace(NavigationRoutes.Store)}>
            {t('common:browse_plan')}
          </Button>
        )
      }
    />
  );
}
